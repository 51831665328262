import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ContactRegistrationModal from 'components/modals/ContactRegistrationModal';
import ContactCustomerEditModal from 'components/modals/CustomerContactEditModal';
import Pagination from 'components/pagination';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';

const ContactRegistration = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);

  const [selectedContact, setSelectedContact] = useState({});
  const [error, setError] = useState({});
  const [filterName, setFilterName] = useState('');
  const [success, setSuccess] = useState('');
  const [contacts, setContacts] = useState({
    content: [
      {
        name: '',
        email: '',
        phone: '',
        phone: '',
        customers: []
      }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    name: '',
    includeInactives: false
  });

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.contacts.list(queryParams);
    if (response.content) setContacts(response);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    setSuccessEdit(false);
  }, [selectedContact]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'email',
      header: 'E-mail',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'landline',
      header: 'Telefone',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'phone',
      header: 'Celular',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'customers',
      header: 'Clientes',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { customers } = rowData.row.original;
        return <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
            display: 'inline'
          }}
          title={customers.map(customer => customer.fantasyName || customer.name).join(', ')} // Tooltip com texto completo
        >
          {customers.map((customer, index) => (
            <span key={index}>
              {customer.fantasyName || customer.name}
              {index !== customers.length - 1 && ', '}
            </span>
          ))}
        </div>
      }
    },
    {
      accessorKey: 'inactive',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return inactive ? (
          <span className="text-danger">Inativo</span>
        ) : (
          <span className="text-success">Ativo</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <button
            className="btn btn-link btn-sm"
            onClick={() => handleEdit(item)}
          >
            <FontAwesomeIcon icon={faPen} size="sm" />
          </button>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: contacts.content,
    columns
  });

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };

  const handleEdit = item => {
    setSelectedContact(item);
    setShowModalEdit(true);
  };

  const editContact = async bodyChange => {
    const response = await api.contacts.edit(selectedContact.id, bodyChange);
    if (response.id) {
      setSuccess('Contato editado com sucesso');
      setShowAlert(true);
      getData();
      handleCloseModalEdit();
      setSuccessEdit(true);
    } else {
      setError(response);
      setShowAlertError(true);
    }
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showAlert}
        setShowAlert={() => setShowAlert(false)}
      />
      <FailedRequest
        message={error.userMessage}
        showAlert={showAlertError}
        setShowAlert={() => setShowAlertError(false)}
      />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Contatos">
          <Button
            type="button"
            variant="primary"
            size="sm"
            onClick={() => setShowModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
            Novo
          </Button>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <ContactCustomerEditModal
            showModal={showModalEdit}
            refreshData={() => getData()}
            data={selectedContact}
            handleClose={handleCloseModalEdit}
            handleSubmit={editContact}
            isSuccess={successEdit}
          />

          <ContactRegistrationModal
            showModal={showModal}
            handleClose={handleCloseModalRegister}
            newContact={response => { }}
            isSuccess={response => {
              if (response) getData();
            }}
          />

          <Row className="mb-3">
            <Col>
              <InputGroup className="position-relative input-search-width">
                <FormControl
                  size="sm"
                  id="search"
                  type="search"
                  className="shadow-none"
                  placeholder="Buscar"
                  value={filterName}
                  onChange={e => {
                    if (e.target.value === '')
                      setFilters({
                        ...filters,
                        page: 0,
                        name: ''
                      })
                    setFilterName(e.target.value);
                  }}
                />
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="border-300 hover-border-secondary"
                  onClick={() =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      page: 0,
                      name: filterName
                    }))
                  }
                >
                  <FontAwesomeIcon icon="search" className="fs-10" />
                </Button>
              </InputGroup>
            </Col>

            <Col className="col-auto px-3">
              <Form.Select
                size="sm"
                className="mb-3 rounded-pill"
                onChange={e =>
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    includeInactives: e.target.value
                  }))
                }
              >
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>

          <Pagination
            data={contacts}
            filters={filters}
            setFilters={setFilters}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default ContactRegistration;
