import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from "helpers/api";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MaskedInput from "react-text-mask";

const ContactRegistrationModal = ({ showModal, handleClose, isSuccess, customer, newContact }) => {
  const api = useApi();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customers, setCustomers] = useState([]);
  const [showMenuMainCustomer, setShowMenuMainCustomer] = useState(false);
  const [filters, setFilters] = useState({ size: 100, includeInactives: false, name: '' });
  const [body, setBody] = useState({
    name: '',
    email: '',
    phone: '',
    landline: '',
    inactive: false,
    customer: { id: '' }
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    getCustomers();
  }, [filters.name]);

  useEffect(() => {
    setSelectedCustomer(customer);
    customer && setFilters(prevFilters => ({ ...prevFilters, name: customer.name }));
  }, [customer]);

  useEffect(() => {
    if (showModal) {
      clearFields();
      setValidated(false);
    }
  }, [showModal]);

  const getCustomers = async () => {
    if (filters.name != '') {
      const queryParams = new URLSearchParams(filters).toString();
      let response = await api.customers.list(queryParams);
      setCustomers(response.content);
    }
  };

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);

      const newBody = {
        name: body.name,
        email: body.email === '' ? null : body.email,
        phone: body.phone === '' ? null : body.phone,
        landline: body.landline === '' ? null : body.landline,
        inactive: body.inactive,
        customer: { id: selectedCustomer ? selectedCustomer.id : body.customer.id }
      };

      const response = await api.contacts.create(newBody);
      if (response.status) {
        setError(response);
        setShowAlertError(true);
        isSuccess(false);
        setValidated(true);
      } else {
        isSuccess(true);
        newContact(response);
        setShowAlert(true);
        setSuccess("Contato cadastrado com sucesso");
        handleClose();
        clearFields();
      }
    }
  };

  const clearFields = () => {
    setBody({
      name: '',
      email: '',
      phone: '',
      landline: '',
      inactive: false,
      customer: { id: '' }
    });
    setFilters({ ...filters, name: '' });
  };

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.userMessage} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo contato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.name}
                onChange={e => setBody(prevState => ({ ...prevState, name: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>E-mail {body.phone === '' && body.landline === '' && <span className="text-danger">*</span>}</Form.Label>
              <Form.Control
                required={body.phone === '' && body.landline === ''}
                type="email"
                placeholder="E-mail"
                value={body.email}
                onChange={e => setBody(prevState => ({ ...prevState, email: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um e-mail.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="agent.phone">
              <Form.Label>Telefone {body.email === '' && body.phone === '' && <span className="text-danger">*</span>}</Form.Label>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(00) 0000-0000"
                required={body.email === '' && body.phone === ''}
                guide={false}
                value={body.landline}
                onChange={e => setBody(prevBody => ({ ...prevBody, landline: e.target.value }))}
                render={(ref, props) => (
                  <Form.Control ref={ref} {...props} />
                )}
              />

              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um número de telefone.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="agent.phone">
              <Form.Label>Celular {body.email === '' && body.landline === '' && <span className="text-danger">*</span>}</Form.Label>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(00) 00000-0000"
                required={body.email === '' && body.landline === ''}
                guide={false}
                value={body.phone}
                onChange={e => setBody(prevBody => ({ ...prevBody, phone: e.target.value }))}
                render={(ref, props) => (
                  <Form.Control ref={ref} {...props} />
                )}
              />

              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um número de celular.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Ativo</Form.Label>
              <Form.Check
                type="switch"
                value={body.inactive}
                checked={!body.inactive}
                disabled
                onChange={e => setBody({ ...body, inactive: !body.inactive })} />
            </Form.Group>

            <Form.Group controlId="contact.customer">
              <Form.Label>Cliente principal <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type='search'
                placeholder='Digite para pesquisar'
                value={filters.name}
                onFocus={() => setShowMenuMainCustomer(true)}
                onChange={e => setFilters(prevFilters => ({ ...prevFilters, name: e.target.value }))} />
              {showMenuMainCustomer &&
                <ul className='px-0 list-customer bg-white shadow rounded z-3' style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}>
                  {customers.map(item => (
                    <li
                      style={{ listStyleType: 'none', cursor: 'pointer' }}
                      className='px-3 py-2 bg-primary-hover item-list-hover'
                      data-value={item.id}
                      key={item.id + item.type} onClick={() => {
                        setFilters(prevFilters => ({ ...prevFilters, name: item.fantasyName || item.name }));
                        setBody(prevFilters => ({ ...prevFilters, customer: { id: item.id } }));
                        setShowMenuMainCustomer(false);
                      }}>
                      {item.fantasyName || item.name}
                    </li>
                  ))}
                </ul>
              }
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um cliente.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactRegistrationModal;