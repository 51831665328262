import MultiSelect from 'components/common/MultiSelect';
import useApi from 'helpers/api';
import { formatDateUs, getStartOfWeek, priorities } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const TicketFilteringForm = ({ filters, setFilters, clearFilters }) => {
  const api = useApi();

  const today = formatDateUs(new Date());
  const yesterday = formatDateUs(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  const last7Days = formatDateUs(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
  const currentMonth = formatDateUs(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const last30Days = formatDateUs(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000));
  const currentWeek = formatDateUs(getStartOfWeek());

  const inputCustomer = useRef(null);

  const [showMenuCustomer, setShowMenuCustomers] = useState(false);

  const [customers, setCustomers] = useState({ content: [] });
  const [agents, setAgents] = useState({ content: [] });
  const [serviceCatalogs, setServiceCatalogs] = useState({ content: [] });

  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [formattedPriorities, setFormattedPriorities] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [formattedStatus] = useState([
    { value: "TO_DO", label: "A fazer" },
    { value: "IN_PROGRESS", label: "Atendendo" },
    { value: "PENDING", label: "Pausado" },
    { value: "COMPLETED", label: "Concluído" },
    { value: "CLOSED", label: "Fechado" }
  ]);

  const [selectedAgents, setSelectedAgents] = useState([]);
  const [formattedAgents, setFormattedAgents] = useState([]);

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [formattedContacts, setFormattedContacts] = useState([]);

  const [selectedServiceCatalog, setSelectedServiceCatalog] = useState([]);
  const [formattedServiceCatalog, setFormattedServiceCatalog] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [formattedCategories, setFormattedCategories] = useState([]);

  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [formattedSubCategories, setFormattedSubCategories] = useState([]);

  const [formattedOrigins, setFormattedOrigins] = useState([]);
  const [selectedOrigins, setSelectedOrigins] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [categories, setCategories] = useState({ content: [] });
  const [subcategories, setSubcategories] = useState({ content: [] });
  const [filtersQuery, setFiltersQuery] = useState({ size: 1000, name: '' });
  const [filtersPreSave, setFiltersPreSave] = useState({
    page: 0,
    size: 10,
    code: '',
    priorities: '',
    origins: '',
    status: '',
    customer: '',
    contacts: '',
    serviceCatalog: '',
    category: '',
    subcategory: '',
    subject: '',
    agent: '',
    serviceGroup: '',
    typeTicket: '',
    initialCreationDate: '',
    endCreationDate: '',
    initialSolutionDate: '',
    endSolutionDate: '',
  });
  const queryParams = new URLSearchParams(filtersQuery).toString();

  useEffect(() => {
    getAgents();
    getServiceCatalogs();
    getCategories();
    getSubcategories();
    getContacts();
    getOrigins();
  }, []);

  useEffect(() => {
    setFormattedPriorities(priorities.map(item => ({
      value: item.value,
      label: item.name
    })))
  }, [priorities]);

  useEffect(() => {
    setFormattedOrigins(origins.map(item => ({
      value: item.ticketOrigin,
      label: item.description
    })))
  }, [origins]);

  useEffect(() => {
    setFormattedAgents(agents.content.map(item => ({
      value: parseInt(item.id),
      label: item.firstName
    })));
  }, [agents]);

  useEffect(() => {
    setFormattedServiceCatalog(serviceCatalogs.content.map(item => ({
      value: parseInt(item.id),
      label: item.name
    })));
  }, [serviceCatalogs]);

  useEffect(() => {
    setFormattedCategories(categories.content.map(item => ({
      value: parseInt(item.id),
      label: item.name
    })));
  }, [categories]);

  useEffect(() => {
    setFormattedSubCategories(subcategories.content.map(item => ({
      value: parseInt(item.id),
      label: item.name
    })));
  }, [subcategories]);

  useEffect(() => {
    setFormattedContacts(contacts.map(item => ({
      value: parseInt(item.id),
      label: item.name
    })));
  }, [contacts]);

  useEffect(() => {
    handleSetFilters('priorities', selectedPriorities.map(item => item.value));
  }, [selectedPriorities]);

  useEffect(() => {
    handleSetFilters('contacts', selectedContacts.map(item => item.value));
  }, [selectedContacts]);

  useEffect(() => {
    handleSetFilters('status', selectedStatus.map(item => item.value));
  }, [selectedStatus]);

  useEffect(() => {
    handleSetFilters('origins', selectedOrigins.map(item => item.value));
  }, [selectedOrigins]);

  useEffect(() => {
    handleSetFilters('agent', selectedAgents.map(item => parseInt(item.value)));
  }, [selectedAgents]);

  useEffect(() => {
    handleSetFilters('serviceCatalog', selectedServiceCatalog.map(item => parseInt(item.value)));
  }, [selectedServiceCatalog]);

  useEffect(() => {
    handleSetFilters('category', selectedCategories.map(item => parseInt(item.value)));
  }, [selectedCategories]);

  useEffect(() => {
    handleSetFilters('subcategory', selectedSubCategories.map(item => parseInt(item.value)));
  }, [selectedSubCategories]);

  useEffect(() => {
    getCustomers();
  }, [filtersQuery.name]);

  const getCustomers = async () => {
    if (filtersQuery.name) {
      const params = new URLSearchParams(filtersQuery).toString();
      let response = await api.customers.list(params);
      setCustomers(response);
    }
  };
  const getContacts = async () => {
    let response = await api.contacts.list(queryParams);
    setContacts(response.content);
  };
  const getAgents = async () => {
    let response = await api.agents.list(queryParams);
    setAgents(response);
  };
  const getServiceCatalogs = async () => {
    let response = await api.serviceCatalogs.list(queryParams);
    setServiceCatalogs(response);
  };
  const getCategories = async () => {
    let response = await api.categories.list(queryParams);
    setCategories(response);
  };
  const getSubcategories = async () => {
    let response = await api.subcategories.list(queryParams);
    setSubcategories(response);
  };
  const getOrigins = async () => {
    const response = await api.ticketOrigins.list();
    setOrigins(response);
  };

  const handleSetFilters = (filterName, value) => {
    setFiltersPreSave(prevState => ({ ...prevState, [filterName]: value }));
  };

  const changeCreationDate = e => {
    const dates = e.target.value.split("/");
    handleSetFilters('initialCreationDate', dates[0]);
    handleSetFilters('endCreationDate', dates[1]);
  };

  const changeSolutionDate = e => {
    const dates = e.target.value.split("/");
    handleSetFilters('initialSolutionDate', dates[0]);
    handleSetFilters('endSolutionDate', dates[1]);
  };

  const clearFiltersPreSave = () => {
    setSelectedAgents([]);
    setSelectedCategories([]);
    setSelectedServiceCatalog([]);
    setSelectedPriorities([]);
    setSelectedStatus([]);
    setSelectedOrigins([]);
    setSelectedSubCategories([]);
    setFiltersPreSave({
      page: 0,
      size: 10,
      code: '',
      priorities: '',
      status: '',
      origins: '',
      customer: '',
      contacts: '',
      serviceCatalog: '',
      category: '',
      subcategory: '',
      subject: '',
      agent: '',
      serviceGroup: '',
      typeTicket: '',
      initialCreationDate: '',
      endCreationDate: '',
      initialSolutionDate: '',
      endSolutionDate: '',
    })
  };

  return (
    <Card style={{ height: '85vh', overflowY: 'auto' }} className={`position-relative shadow-none shadow-show-xl scrollbar`}>
      <Card.Header className="bg-100 d-none d-xl-block">
        <h6 className="mb-0">Filtro</h6>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs-10">Número do ticket</Form.Label>
            <Form.Control
              type='number'
              placeholder='Número do ticket'
              value={filtersPreSave.code}
              onChange={e => handleSetFilters('code', e.target.value)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 fs-10">Prioridade</Form.Label>
            <MultiSelect
              value={selectedPriorities}
              options={formattedPriorities}
              placeholder='Selecione as prioridades'
              onChange={selectedOptions => setSelectedPriorities(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Status</Form.Label>
            <MultiSelect
              value={selectedStatus}
              options={formattedStatus}
              placeholder='Selecione os status'
              onChange={selectedOptions => setSelectedStatus(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Origem</Form.Label>
            <MultiSelect
              value={selectedOrigins}
              options={formattedOrigins}
              placeholder='Selecione as origens do ticket'
              onChange={selectedOptions => setSelectedOrigins(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Cliente</Form.Label>
            <Form.Control
              type='text'
              placeholder='Digite para pesquisar'
              value={filtersQuery.name}
              ref={inputCustomer}
              onFocus={() => setShowMenuCustomers(true)}
              onChange={e => setFiltersQuery(prevFilters => ({ ...prevFilters, name: e.target.value }))} />
            {showMenuCustomer &&
              <ul className='px-0 list-customer bg-100 shadow rounded z-3' style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}>
                {customers.content.map(item => (
                  <li
                    style={{ listStyleType: 'none', cursor: 'pointer' }}
                    className='px-3 py-2 bg-primary-hover item-list-hover'
                    data-value={item.id}
                    key={item.id + item.type} onClick={() => {
                      setFiltersQuery(prevFilters => ({ ...prevFilters, name: item.fantasyName || item.name }));
                      handleSetFilters('customer', item.id);
                      setShowMenuCustomers(false);
                    }}>
                    {item.fantasyName || item.name}
                  </li>
                ))}
              </ul>
            }
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Selecione um cliente.</Form.Control.Feedback>
          </div>
          <div className='mb-2'>
            <Form.Label className="mb-1 mt-2 fs-10">Contato</Form.Label>
            <MultiSelect
              value={selectedContacts}
              options={formattedContacts}
              placeholder='Selecione os contatos'
              onChange={selectedOptions => setSelectedContacts(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Agente</Form.Label>
            <MultiSelect
              value={selectedAgents}
              options={formattedAgents}
              placeholder='Selecione os agentes'
              onChange={selectedOptions => setSelectedAgents(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Catalogo de serviço</Form.Label>
            <MultiSelect
              value={selectedServiceCatalog}
              options={formattedServiceCatalog}
              placeholder='Selecione os catálogos'
              onChange={selectedOptions => setSelectedServiceCatalog(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Categoria</Form.Label>
            <MultiSelect
              value={selectedCategories}
              options={formattedCategories}
              placeholder='Selecione as categorias'
              onChange={selectedOptions => setSelectedCategories(selectedOptions)} />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs-10">Subcategoria</Form.Label>
            <MultiSelect
              value={selectedSubCategories}
              options={formattedSubCategories}
              placeholder='Selecione as sub-categorias'
              onChange={selectedOptions => setSelectedSubCategories(selectedOptions)} />
          </div>
          <div>
            <Form.Label className="mb-1 mt-2 fs-10">Criação</Form.Label>
            <div className='d-flex justify-content-between'>
              <Form.Select value={`${filtersPreSave.initialCreationDate}/${filtersPreSave.endCreationDate}`} onChange={changeCreationDate}>
                <option value={'/'}>
                  Qualquer hora
                </option>
                <option value={`${today}/${today}`}>
                  Hoje
                </option>
                <option value={`${yesterday}/${yesterday}`}>
                  Ontem
                </option>
                <option value={`${currentWeek}/${today}`}>
                  Esta semana
                </option>
                <option value={`${last7Days}/${today}`}>
                  Nos últimos 7 dias
                </option>
                <option value={`${currentMonth}/${today}`}>
                  Este mês
                </option>
                <option value={`${last30Days}/${today}`}>
                  Nos últimos 30 dias
                </option>
              </Form.Select>
            </div>
          </div>
          <div>
            <Form.Label className="mb-1 mt-2 fs-10">Conclusão</Form.Label>
            <div className='d-flex justify-content-between'>
              <Form.Select value={`${filtersPreSave.initialSolutionDate}/${filtersPreSave.endSolutionDate}`} onChange={changeSolutionDate}>
                <option value={'/'}>
                  Qualquer hora
                </option>
                <option value={`${today}/${today}`}>
                  Hoje
                </option>
                <option value={`${yesterday}/${yesterday}`}>
                  Ontem
                </option>
                <option value={`${currentWeek}/${today}`}>
                  Esta semana
                </option>
                <option value={`${last7Days}/${today}`}>
                  Nos últimos 7 dias
                </option>
                <option value={`${currentMonth}/${today}`}>
                  Este mês
                </option>
                <option value={`${last30Days}/${today}`}>
                  Nos últimos 30 dias
                </option>
              </Form.Select>
            </div>
          </div>
        </Form>
      </Card.Body>
      <Card.Footer className="position-sticky bottom-0 d-flex justify-content-end bg-100 border-top border-200 py-x1">
        <Button variant="falcon-default" className="me-2 border border-secondary-subtle" onClick={() => {
          clearFilters();
          clearFiltersPreSave();
          setFiltersQuery(prevFilters => ({ ...prevFilters, name: '' }));
        }}>
          Limpar
        </Button>

        <Button variant="primary" onClick={() => {
          setFilters(filtersPreSave)
        }}>
          Filtrar
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default TicketFilteringForm;